import { mixed, number, object, string } from 'yup';

import { BeMessage } from '../../../core/beUi';
import {UserRole} from "../../../core/enums/userRole";
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

const validationSchema = object().shape({
    firstName: string().required(() => (
        <BeMessage severity="error" text="First name is required" />
    )),
    lastName: string().required(() => (
        <BeMessage severity="error" text="Last name is required" />
    )),
    email: string()
        .email(() => <BeMessage severity="error" text="Email is invalid" />)
        .required(() => (
            <BeMessage severity="error" text="Email is required" />
        )),
    phoneNumber: string()
        .test({
            message: () => (
                <BeMessage severity="error" text="Phone number is invalid" />
            ),
            test: (value, context) => {
                const country = context.parent.country.toUpperCase();
                return phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, country), country);
            },
        })
        .test({
            message: () => (
                <BeMessage severity="error" text="Phone cannot contain white space and special character" />
            ),
            test: (value) => {
                const regex = /^[a-zA-Z0-9@]+$/g;
                return regex.test(value||'');
            },
        })
        .required(() => (
            <BeMessage severity="error" text="Phone number is required" />
        )),
    country: string()
        .oneOf(['AU', 'NZ'])
        .required(() => (
            <BeMessage severity="error" text="Please choose a country" />
        )),
    role: mixed<UserRole>()
        .notOneOf([UserRole.SUPER_ADMIN])
        .required(() => <BeMessage severity="error" text="Role is required" />),
    threshold: number()
        .min(1, () => (
            <BeMessage
                severity="error"
                text="Threshold should be positive number"
            />
        ))
        .required(() => (
            <BeMessage severity="error" text="Threshold is required" />
        )),
    bonus: number()
        .min(1, () => (
            <BeMessage severity="error" text="Bonus should't be more than 99" />
        ))
        .max(99, () => (
            <BeMessage severity="error" text="Bonus should't exceed 99" />
        ))
        .required(() => (
            <BeMessage severity="error" text="Bonus is required" />
        )),
});

export default validationSchema;
