import flattenDeep from 'lodash/flattenDeep';
import {Navigate, Route, Routes as ReactRoutes} from 'react-router-dom';
import {RouteConfig} from "./interfaces/routeConfig";
import ProtectedRoutes from "./protectedRoutes";
import {UserRole} from "../core/enums/userRole";
import React from "react";
import {SubscriptionDetails, Workspace} from "../core/contexts/User/types";


const generateFlattenRoutes = (routes: RouteConfig[]): RouteConfig[] => {
    if (!routes) return [];
    return flattenDeep(
        routes.map(({ routes: subRoutes, ...rest }) => [
            rest,
            generateFlattenRoutes(subRoutes ?? [])
        ])
    );
}

export const renderRoutes = (mainRoutes: RouteConfig[]) => {
    return ({
                isLoggedOut,
                isAuthorized,
                role,
                hasActiveSubscription,
                subscriptionDetails,
                selectedWorkspace
            }: {
        isLoggedOut: boolean;
        isAuthorized: boolean;
        role: UserRole | undefined;
        hasActiveSubscription: boolean | null;
        subscriptionDetails: SubscriptionDetails | undefined | null;
        selectedWorkspace: Workspace | null | undefined; // <- Ensure workspace is included
    }) => {

        // Prevent early redirection by waiting for selectedWorkspace to load
        if (selectedWorkspace === undefined) {
            return <></>;
        }

        const layouts = mainRoutes.map(({ layout: Layout, routes }, index) => {
            if (routes && Layout) {
                const subRoutes = generateFlattenRoutes(routes);

                return (
                    <Route key={index} element={<Layout />}>
                        {subRoutes
                            .filter((s) => s.isPublic || (role && s.userRole?.includes(role) || !s.userRole))
                            .map(({ component: Component, path, name, isPublic, allowWithNoSubscription }) => {
                                return (
                                    <Route
                                        key={name}
                                        element={
                                            hasActiveSubscription || (!hasActiveSubscription && (allowWithNoSubscription || isPublic))
                                                ? <ProtectedRoutes isAuthorized={isAuthorized} isPublic={isPublic} />
                                                : subscriptionDetails !== null && subscriptionDetails?.isSubscriptionOwner
                                                    ? hasActiveSubscription !== null
                                                        ? <Navigate to='/billing' />
                                                        : <></>
                                                    : isLoggedOut ? <Navigate to='/' />  : <Navigate to='/user/profile' />
                                        }
                                    >
                                        {Component && path && (
                                            <Route element={<Component />} path={path} />
                                        )}
                                    </Route>
                                );
                            })}
                    </Route>
                );
            }
        });

        return <ReactRoutes>{layouts}</ReactRoutes>;
    };
};
