import {SubscriptionStatus} from "../../core/contexts/User/types";
import {BeBadge} from "../../core/beUi";
import {DateTime} from "luxon";
import React from "react";

export const getSubscriptionStatusBadge = (status: SubscriptionStatus, trialEnd: string, cancelAtPeriodEnd: boolean, hasPaymentMethod: boolean) => {
    if(cancelAtPeriodEnd && status !== SubscriptionStatus.CANCELED) {
        return <BeBadge severity={"warning"} variant={"outlined"}>Cancellation scheduled for end of billing period</BeBadge>;
    } else if(!hasPaymentMethod) {
        return <BeBadge severity={"warning"} variant={"outlined"}>Payment details required</BeBadge>;
    } else {
        switch (status) {
            case SubscriptionStatus.ACTIVE:
                return <BeBadge severity={"success"} variant={"outlined"}>Active</BeBadge>;
            case SubscriptionStatus.CANCELED:
                return <BeBadge severity={"danger"} variant={"outlined"}>Cancelled</BeBadge>;
            case SubscriptionStatus.INCOMPLETE:
                return <BeBadge severity={"info"} variant={"outlined"}>Incomplete</BeBadge>;
            case SubscriptionStatus.INCOMPLETE_EXPIRED:
                return <BeBadge severity={"danger"} variant={"outlined"}>Expired</BeBadge>;
            case SubscriptionStatus.TRIALING:
                return <BeBadge severity={"info"}
                                variant={"outlined"}>{`Trial ends ${DateTime.fromISO(trialEnd).toLocaleString({
                    month: 'short',
                    day: 'numeric'
                })}`}</BeBadge>;
            case SubscriptionStatus.PAST_DUE:
                return <BeBadge severity={"warning"} variant={"outlined"}>Payment due</BeBadge>;
            case SubscriptionStatus.UNPAID:
                return <BeBadge severity={"warning"} variant={"outlined"}>Payment failed</BeBadge>;
        }
    }
}