import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { useContext, useEffect, useMemo, useState } from 'react';
import { getStripe } from '../../core/utils';
import { UserContext } from '../../core/contexts';
import BillingLoading from './billingLoading';
import BillingForm from './billingForm';
import { SubscriptionStatus } from '../../core/contexts/User/types';
import { Navigate } from 'react-router-dom';
import getPipelineAxios from "../../core/utils/getPipelineAxios";
import {createSetupIntentProdApiBillingCreateSetupIntentPost} from "../../clients/pipeline";

const BillingPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [clientSecret, setClientSecret] = useState<string | null>(null);
    const { userAttributes, subscriptionDetails, selectedWorkspace } = useContext(UserContext);

    useEffect(() => {
        const fetchSetupIntent = async () => {
            if(userAttributes && selectedWorkspace) {
                try {
                    const axios = await getPipelineAxios();
                    const response: any = await createSetupIntentProdApiBillingCreateSetupIntentPost({
                        client: axios,
                        query: {
                            workspaceId: selectedWorkspace.id
                        }
                    });
                    const secret = response?.data?.results?.clientSecret;
                    if (secret) {
                        setClientSecret(secret);
                    } else {
                        throw new Error('Client secret not found');
                    }
                } catch (error) {
                    console.error('Error fetching setup intent:', error);
                    setHasError(true);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchSetupIntent();
    }, [userAttributes]);

    const stripe = useMemo(() => getStripe(), []);

    if (subscriptionDetails?.status === SubscriptionStatus.CANCELED && subscriptionDetails?.hasPaymentMethod === true) {
        return <Navigate to="/user/profile" />;
    }

    if (isLoading) {
        return <BillingLoading />;
    }

    if (hasError || !clientSecret) {
        return null;
    }

    const stripeElementOptions: StripeElementsOptions = {
        clientSecret,
        loader: 'always',
        appearance: {
            theme: 'stripe',
            variables: {
                colorPrimary: '#212529',
                colorText: '#212529',
            },
        },
    };

    return (
        <Elements options={stripeElementOptions} stripe={stripe}>
            <BillingForm />
        </Elements>
    );
};

export default BillingPage;