import * as actionTypes from "../../../core/state/actions";
import {produce} from "immer";
import {UnknownAction} from "redux";
import {UserState} from "../interfaces/userState";

const initialState: UserState = {
    isLoadingUsers: false,
    users: [],
    totalUsers: 0,
    isLoadingSelectedUser: false,
    selectedUser: null
};

const usersReducer = (state: UserState = initialState, action: UnknownAction): UserState => {
    switch (action.type) {
        case actionTypes.USERS_LOAD:
            return produce(state, draft => {
                draft.isLoadingUsers = true;
            });
        case actionTypes.USERS_LOADED:
            return produce(state, draft => {
                draft.users = (action.users as any).map((u: any) => ({
                    id: u.id,
                    cognitoSub: u.cognitoSub,
                    firstName: u.firstname,
                    lastName: u.lastname,
                    email: u.email,
                    astuteId: u.astute_id,
                    isActive: u.active_role?.isactive,
                    role: u.active_role?.role,
                }));
                draft.totalUsers = action.totalUsers as number;
                draft.isLoadingUsers = false;
            });
        case actionTypes.USERS_LOADED_ERROR:
            return produce(state, draft => {
                draft.users = [];
                draft.isLoadingUsers = false;
            });
        case actionTypes.USERS_SELECT_USER:
            return produce(state, draft => {
                draft.selectedUser = null;
                draft.isLoadingSelectedUser = true;
            });
        case actionTypes.USERS_SELECTED_USER:
            return produce(state, draft => {
                const u: any = action.user;
                draft.selectedUser = {
                    id: u.id,
                    firstName: u.firstname,
                    lastName: u.lastname,
                    email: u.email,
                    code: u.code,
                    phoneNumber: u.phonenumber,
                    country: u.country,
                    role: u.active_role?.role,
                    bonusDetails: u.bonus_details,
                    astuteId: u.astute_id
                };
                draft.isLoadingSelectedUser = false;
            });
        case actionTypes.USERS_UNSELECT_USER:
            return produce(state, draft => {
                draft.selectedUser = null;
                draft.isLoadingSelectedUser = false;
            });
        case actionTypes.USERS_SELECTED_USER_ERROR:
            return produce(state, draft => {
                draft.selectedUser = null;
                draft.isLoadingSelectedUser = false;
            });
        default:
            return state;
    }

}
export default usersReducer;