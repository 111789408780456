import {useAuth, useToast} from "../../core/hooks";
import React, {useContext, useEffect, useState} from "react";
import {getRoleString} from "../../core/utils/getRoleString";
import {UserCircle} from "@phosphor-icons/react";
import SubscriptionDetails from "../billing/components/subscriptionDetails/subscriptionDetails";
import ContactDetails from "./components/contactDetails";
import RenewSubscription from "../billing/components/renewSubscription/renewSubscription";

const UserProfilePage = () => {

    const [role, setRole] = useState('');
    const { userAttributes, selectedWorkspace } = useAuth();


    useEffect(() => {
        if(userAttributes && selectedWorkspace && selectedWorkspace.role) {
            setRole(getRoleString(selectedWorkspace.role));
        }
    }, [userAttributes, selectedWorkspace]);



    return(<>
            <div className="flex flex-col gap-y-6">
                <div className="flex items-center gap-x-4">
                    <div><UserCircle size="4.5rem" weight="fill"/></div>
                    <div className="flex-1">
                        <h1 className="text-4xl font-bold text-black">{userAttributes?.given_name} {userAttributes?.family_name}</h1>
                        <h5 className="text-xl text-black">{role} at {selectedWorkspace?.name}</h5>
                    </div>
                    <div></div>
                </div>


                <div className="flex max-w-3xl flex-col gap-y-6">
                    <SubscriptionDetails />
                    <ContactDetails />
                </div>

            </div>
        </>
    );
}

export default UserProfilePage;