import { AuthUser } from '@aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { ReactNode, useCallback, useEffect, useState } from 'react';

import UserContext from './UserContext';
import { getCurrentUser, getCurrentUserAttributes, getSubscriptionStatus } from './utils';
import { SubscriptionDetails, SubscriptionStatus, UserAttributes, Workspace } from './types';

const LOCAL_STORAGE_KEY = 'selectedWorkspaceId';

const UserContextProvider = ({
     children,
 }: Readonly<{ children: ReactNode }>) => {
    const [currentUser, setCurrentUser] = useState<AuthUser | null>(null);
    const [userAttributes, setUserAttributes] = useState<UserAttributes | null>(null);
    const [subscriptionDetails, setSubscriptionDetails] = useState<SubscriptionDetails | null>(null);
    const [hasActiveSubscription, setHasActiveSubscription] = useState<boolean | null>(null);
    const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
    const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const refreshUserAttributes = useCallback(async () => {
        const ua = await getCurrentUserAttributes();
        if(ua) {
            setUserAttributes(ua);
            setWorkspaces(ua?.workspaces || []);
        }
    }, []);

    const switchWorkspace = useCallback(async (workspaceId: number) => {
        const newWorkspace = workspaces.find(w => w.id === workspaceId);
        if (!newWorkspace) return;

        setIsLoading(true);
        setSelectedWorkspace(newWorkspace);

        // Persist the selected workspace ID in localStorage
        localStorage.setItem(LOCAL_STORAGE_KEY, String(workspaceId));

        // Fetch new subscription details
        const sd = await getSubscriptionStatus(newWorkspace.id);
        if (sd) {
            setSubscriptionDetails(sd);
        }

        setIsLoading(false);
    }, [workspaces]);

    useEffect(() => {
        const sub = subscriptionDetails
            ? [SubscriptionStatus.ACTIVE, SubscriptionStatus.TRIALING].includes(subscriptionDetails.status) &&
            subscriptionDetails.hasPaymentMethod
            : null;
        setHasActiveSubscription(sub);
    }, [subscriptionDetails]);

    useEffect(() => {
        const updateUser = async () => {
            setIsLoading(true);

            const user = await getCurrentUser();
            const attributes = await getCurrentUserAttributes();
            const workspaceList = attributes?.workspaces || [];

            // Retrieve selected workspace ID from localStorage
            const savedWorkspaceId = localStorage.getItem(LOCAL_STORAGE_KEY);
            const initialWorkspace = workspaceList.find(ws => String(ws.id) === savedWorkspaceId) || workspaceList[0] || null;

            const subDetails = initialWorkspace ? await getSubscriptionStatus(initialWorkspace.id) : null;

            if(user && attributes && subDetails) {
                setCurrentUser(user);
                setUserAttributes(attributes);
                setWorkspaces(workspaceList);
                setSelectedWorkspace(initialWorkspace);
                setSubscriptionDetails(subDetails);
            }
            setIsLoading(false);
            setIsLoading(false);
        };

        const hubListenerRemove = Hub.listen('auth', updateUser);
        updateUser();
        return () => hubListenerRemove();
    }, []);

    return (
        <UserContext.Provider
            value={{
                currentUser,
                userAttributes,
                isLoading,
                refreshUserAttributes,
                subscriptionDetails,
                hasActiveSubscription,
                workspaces,
                selectedWorkspace,
                switchWorkspace,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserContextProvider;
