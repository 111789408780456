import * as actionTypes from "../../../core/state/actions";
import {produce} from "immer";
import {UnknownAction} from "redux";
import {DashboardState} from "../interfaces/dashboardState";
import {PlacementScoreboard} from "../interfaces/placementScoreboard";
import {DateTime} from "luxon";
import {BillingSummary} from "../interfaces/billingSummary";
import {LineGraphData} from "../interfaces/lineGraphData";

const initialState: DashboardState = {
    isLoadingLatestPlacements: false,
    latestPlacements: [],
    totalLatestPlacements: 0,
    isLoadingBillingSummary: false,
    billingSummary: {
        threshold: 0,
        bonus: 0,
        total: 0,
        bonus_amount: 0,
        loaded_at: DateTime.fromISO(new Date().toISOString()).toLocaleString(DateTime.DATE_MED)
    },
    isLoadingLineGraphData: false,
    lineGraphData: []
};

const dashboardReducer = (state: DashboardState = initialState, action: UnknownAction): DashboardState => {
    switch (action.type) {
        case actionTypes.DASHBOARD_BILLING_LINE_GRAPH_LOAD:
            return produce(state, draft => {
                draft.isLoadingLineGraphData = true;
            });
        case actionTypes.DASHBOARD_BILLING_LINE_GRAPH_LOADED:
            return produce(state, draft => {
                draft.lineGraphData = action.data as LineGraphData[];
                draft.isLoadingLineGraphData = false;
            });
        case actionTypes.DASHBOARD_BILLING_SUMMARY_LOAD:
            return produce(state, draft => {
                draft.isLoadingBillingSummary = true;
            });
        case actionTypes.DASHBOARD_BILLING_SUMMARY_LOADED:
            return produce(state, draft => {
                const summary = action.summary as BillingSummary;
                draft.billingSummary =  {
                    threshold:  summary.threshold ? Number(summary.threshold.toFixed(2)) : 0,
                    bonus:  Math.round(summary.bonus * 100),
                    total:  summary.total ? Number(summary.total.toFixed(2)) : 0,
                    bonus_amount:  summary.bonus_amount ? Number(summary.bonus_amount.toFixed(2)) : 0,
                    loaded_at: DateTime.fromISO(summary.loaded_at).toLocaleString(DateTime.DATE_MED)
                };
                draft.isLoadingBillingSummary = false;
            });
        case actionTypes.DASHBOARD_LATEST_PLACEMENTS_LOAD:
            return produce(state, draft => {
                draft.isLoadingLatestPlacements = true;
            });
        case actionTypes.DASHBOARD_LATEST_PLACEMENTS_LOADED:
            return produce(state, draft => {
                draft.latestPlacements = (action.placements as PlacementScoreboard[])
                    .map((p: PlacementScoreboard) => ({
                    candidateName: p.candidate_name,
                    jobTitle: p.job_title,
                    company: p.company,
                    type: p.type,
                    recruiter: p.recruiter,
                    totalAmount: `$${parseFloat(p.profit.toFixed(2)).toLocaleString()}`,
                    split: `${p.split}%`,
                    startDate: DateTime.fromISO(p.start_date).toLocaleString(DateTime.DATE_MED),
                    invoiceId: `INV-${p.invoice_id}`,
                    invoiceDue: DateTime.fromISO(p.invoice_due).toLocaleString(DateTime.DATE_MED),
                    invoiceStatus: p.invoice_status
                }));
                draft.totalLatestPlacements = (action.placements as any).length;
                draft.isLoadingLatestPlacements = false;
            });
        default:
            return state;
    }

}
export default dashboardReducer;