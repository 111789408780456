import {PlacementState} from "../interfaces/placementState";
import {UnknownAction} from "redux";
import * as actionTypes from "../../../core/state/actions";
import {produce} from "immer";
import {PlacementPageLinks} from "../interfaces/placementPageLinks";
import {DateTime} from "luxon";
import {PlacementScoreboard} from "../../dashboard/interfaces/placementScoreboard";


const initialState: PlacementState = {
    isLoadingPlacements: false,
    placements: [],
    totalPlacements: 0,
    pageLinks: {
        first: null,
        prev: null,
        next: null,
        last: null
    }
};

const placementsReducer = (state: PlacementState = initialState, action: UnknownAction): PlacementState => {
    switch (action.type) {
        case actionTypes.PLACEMENTS_LOAD:
            return produce(state, draft => {
                draft.isLoadingPlacements = true;
            });
        case actionTypes.PLACEMENTS_LOADED:
            return produce(state, draft => {
                draft.placements = (action.placements as PlacementScoreboard[])
                    .map((p: PlacementScoreboard) => ({
                        candidateName: p.candidate_name,
                        jobTitle: p.job_title,
                        company: p.company,
                        type: p.type,
                        recruiter: p.recruiter,
                        totalAmount: `$${parseFloat(p.profit.toFixed(2)).toLocaleString()}`,
                        split: `${p.split}%`,
                        startDate: DateTime.fromISO(p.start_date).toLocaleString(DateTime.DATE_MED),
                        invoiceId: `INV-${p.invoice_id}`,
                        invoiceDue: DateTime.fromISO(p.invoice_due).toLocaleString(DateTime.DATE_MED),
                        invoiceStatus: p.invoice_status
                    }));
                draft.pageLinks = action.pageLinks as PlacementPageLinks;
                draft.totalPlacements = action.totalPlacements as number;
                draft.isLoadingPlacements = false;
            });
        case actionTypes.PLACEMENTS_LOADED_ERROR:
            return produce(state, draft => {
                draft.placements = [];
                draft.isLoadingPlacements = false;
            });
        default:
            return state;
    }

}
export default placementsReducer;