import { createContext } from 'react';

import { UserContextValue } from './types';

const UserContext = createContext<UserContextValue>({
    isLoading: false,
    currentUser: null,
    userAttributes: null,
    subscriptionDetails: null,
    selectedWorkspace: null,
    hasActiveSubscription: null,
    workspaces: []
});

export default UserContext;
