import {Dropdown} from 'primereact/dropdown';
import {ArrowLeft, AsteriskSimple, CircleNotch,} from '@phosphor-icons/react';

import {ErrorMessage, Form, Formik} from 'formik';
import {useContext, useState} from 'react';

import {useToast} from '../../../core/hooks';
import {BeButton, BeInputNumber, BeInputText, BeLink} from '../../../core/beUi';
import {ToastContext, UserContext} from '../../../core/contexts';
import {getErrorMessage} from '../../../core/utils';
import validationSchema from './validationSchema';
import {Image} from "primereact/image";
import {useNavigate} from "react-router-dom";
import {UserRole} from "../../../core/enums/userRole";

import {PhoneNumberFormat as PNF} from "google-libphonenumber";
import getPipelineAxios from "../../../core/utils/getPipelineAxios";
import {createUserApiUserPost} from "../../../clients/pipeline";

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

const roles = [
    { label: 'Admin', value: UserRole.ADMIN },
    { label: 'National Manager', value: UserRole.NATIONAL_MANAGER },
    { label: 'Team Manager', value: UserRole.TEAM_MANAGER },
    { label: 'Resourcer', value: UserRole.RESOURCER },
    { label: 'Recruiter', value: UserRole.RECRUITER },
];

const countries = [
    { label: 'Australia', value: 'AU', icon: '/icons/australia.svg' },
    { label: 'New Zealand', value: 'NZ', icon: '/icons/new-zealand.svg' },
];

const selectedCountryCodeTemplate = (option: any, props: any) => {
    if (option) {
        return (
            <div className="flex items-center justify-start gap-2">
                <Image
                    alt={option.value}
                    src={option.icon}
                    width="16"
                    height="16"
                />
                <div>{option.label}</div>
            </div>
        );
    }

    return <span>{props.placeholder}</span>;
};

const countryCodeOptionTemplate = (option: any) => {
    return (
        <div className="flex items-center justify-start gap-2">
            <Image
                alt={option.value}
                src={option.icon}
                width="24"
                height="24"
            />
            <div>{option.label}</div>
        </div>
    );
};

const AddUserPage = () => {
    const toastRef = useContext(ToastContext);
    const { showToast } = useToast(toastRef);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { userAttributes } = useContext(UserContext);

    const handleAddUser = async ({
                                     firstName,
                                     lastName,
                                     phoneNumber,
                                     role,
                                     country,
                                     astuteId,
                                     bonus,
                                     threshold,
                                     ...rest
                                 }: any) => {
        const axios = await getPipelineAxios();
        try {
            setLoading(true);
            const number = phoneUtil.parseAndKeepRawInput(phoneNumber, country);
            await createUserApiUserPost({
                client: axios,
                body: {
                    firstname: firstName,
                    lastname: lastName,
                    phonenumber: phoneUtil.format(number, PNF.E164),
                    astute_id: astuteId,
                    country,
                    bonus_details: {
                        bonus,
                        threshold,
                        workspaceuserroleid: role
                    },
                    ...rest,
                }
            });
            showToast({
                severity: 'success',
                summary: 'success',
                detail: `${firstName} ${lastName} is added to your workspace successfully!`,
            });
            navigate('/users');
        } catch (error) {
            showToast({
                severity: 'error',
                summary: 'failed',
                detail: getErrorMessage(error),
            });
        }
        setLoading(false);
    };

    return (
        <div className="flex flex-col">
            <BeLink
                to="/users"
                size="sm"
                variant="ghost"
                className="mb-6 self-start text-blue-500"
            >
                <ArrowLeft size="1.75rem" />
                Back
            </BeLink>
            <h2 className="mb-4 text-4xl font-bold text-black">Add User</h2>
            <div className="flex flex-col gap-7 lg:flex-row lg:items-start">
                <div className="grow flex-col items-start gap-6 rounded-xl bg-white p-8 shadow-sm">
                    <Formik
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            email: '',
                            phoneNumber: '',
                            country: '',
                            role: undefined,
                            threshold: null,
                            bonus: null,
                            astuteId: ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleAddUser}
                    >
                        {({ values, setFieldValue, handleChange }) => (
                            <Form autoComplete="off">
                                <div className="flex flex-col gap-y-8">
                                    <div className="flex flex-col gap-5">
                                        <h3 className="text-2xl font-bold">
                                            User Details
                                        </h3>
                                        <div className="flex flex-col gap-1">
                                            <div className="flex flex-col justify-stretch gap-4 sm:flex-row">
                                                <div className="flex grow flex-col gap-1">
                                                    <label className="text-sm">
                                                        First Name
                                                    </label>
                                                    <BeInputText
                                                        className="w-full"
                                                        placeholder="Enter first name"
                                                        name="firstName"
                                                        values={values}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="flex grow flex-col gap-1">
                                                    <label className="text-sm">
                                                        Last Name
                                                    </label>
                                                    <BeInputText
                                                        className="w-full"
                                                        placeholder="Enter last name"
                                                        name="lastName"
                                                        values={values}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <ErrorMessage name="firstName"/>
                                            <ErrorMessage name="lastName"/>
                                        </div>
                                        <div className="flex flex-col gap-1">
                                            <label className="text-sm">
                                                Email address
                                            </label>
                                            <BeInputText
                                                className="w-full"
                                                placeholder="Enter email address"
                                                name="email"
                                                type="email"
                                                values={values}
                                                onChange={handleChange}
                                            />
                                            <ErrorMessage name="email"/>
                                        </div>
                                        <div className="flex flex-col gap-1">
                                            <label className="text-sm">
                                                Country
                                            </label>
                                            <Dropdown
                                                name="country"
                                                value={values.country}
                                                onChange={(e) =>
                                                    setFieldValue(
                                                        'country',
                                                        e.value
                                                    )
                                                }
                                                options={countries}
                                                itemTemplate={
                                                    countryCodeOptionTemplate
                                                }
                                                valueTemplate={
                                                    selectedCountryCodeTemplate
                                                }
                                                optionLabel="label"
                                                placeholder="Choose Country"
                                                pt={{
                                                    root: {
                                                        className:
                                                            'hover:border-secondary focus:border-secondary focus:ring-2 focus:ring-secondary/40 shadow-none',
                                                    },
                                                    input: {
                                                        className:
                                                            'py-1.5 px-2 text-sm text-secondary',
                                                    },
                                                    trigger: {
                                                        className:
                                                            'text-secondary-light w-auto py-1.5 px-2',
                                                    },
                                                    item: {
                                                        className:
                                                            'text-secondary aria-selected:bg-primary-light p-2',
                                                    },
                                                    list: {
                                                        className: 'py-2',
                                                    },
                                                }}
                                            />
                                            <ErrorMessage name="country"/>
                                        </div>
                                        <div className="flex flex-col gap-1">
                                            <div className="flex flex-col gap-4 sm:flex-row">
                                                <div className="flex grow flex-col gap-1">
                                                    <label className="text-sm">
                                                        Mobile phone number
                                                    </label>
                                                    <BeInputText
                                                        className="w-full"
                                                        placeholder="Enter phone number"
                                                        name="phoneNumber"
                                                        values={values}
                                                        onChange={handleChange}
                                                        disabled={!values.country}
                                                    />
                                                </div>
                                            </div>
                                            <ErrorMessage name="phoneNumber"/>
                                        </div>

                                        <div className="flex flex-col gap-1">
                                            <label className="text-sm">
                                                Role
                                            </label>
                                            <Dropdown
                                                name="role"
                                                value={values.role}
                                                onChange={(e) =>
                                                    setFieldValue(
                                                        'role',
                                                        e.value
                                                    )
                                                }
                                                options={roles}
                                                optionLabel="label"
                                                placeholder="User role"
                                                pt={{
                                                    root: {
                                                        className:
                                                            'hover:border-secondary focus:border-secondary focus:ring-2 focus:ring-secondary/40 shadow-none',
                                                    },
                                                    input: {
                                                        className:
                                                            'py-1.5 px-2 text-sm text-secondary',
                                                    },
                                                    trigger: {
                                                        className:
                                                            'text-secondary-light w-auto py-1.5 px-2',
                                                    },
                                                    item: {
                                                        className:
                                                            'text-secondary aria-selected:bg-primary-light p-2',
                                                    },
                                                    list: {
                                                        className: 'py-2',
                                                    },
                                                }}
                                            />
                                            <ErrorMessage name="role"/>
                                        </div>
                                        <div className="flex flex-col gap-1">
                                            <label className="text-sm">
                                                Astute Id
                                            </label>
                                            <BeInputText
                                                className="w-full"
                                                placeholder="Astute Id"
                                                name="astuteId"
                                                type="text"
                                                values={values}
                                                onChange={handleChange}
                                            />
                                            <ErrorMessage name="astuteId"/>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-5">
                                        <h3 className="text-2xl font-bold">
                                            Threshold / Bonus
                                        </h3>
                                        <div className="flex flex-col gap-1">
                                            <div className="flex flex-col justify-stretch gap-4 sm:flex-row">
                                                <div className="flex grow flex-col gap-1">
                                                    <label className="text-sm">
                                                        Threshold
                                                    </label>
                                                    <BeInputNumber
                                                        className="w-full"
                                                        placeholder="Enter threshold"
                                                        name="threshold"
                                                        values={values}
                                                        setFieldValue={
                                                            setFieldValue
                                                        }
                                                    />
                                                </div>
                                                <div className="flex grow flex-col gap-1">
                                                    <label className="text-sm">
                                                        Bonus
                                                    </label>

                                                    {/*<div className="p-input-group">*/}
                                                    <BeInputNumber
                                                        className="w-full"
                                                        placeholder="Enter bonus"
                                                        name="bonus"
                                                        values={values}
                                                        setFieldValue={
                                                            setFieldValue
                                                        }
                                                        suffix="%"
                                                        min={1}
                                                        max={99}
                                                    />

                                                    {/*<div className="p-inputgroup-addon">*/}
                                                    {/*    <Percent size="1.75rem" />*/}
                                                    {/*</div>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                            <ErrorMessage name="threshold" />
                                            <ErrorMessage name="bonus" />
                                        </div>
                                    </div>
                                    <BeButton
                                        type="submit"
                                        className="w-32 self-end"
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <CircleNotch
                                                size="1.75rem"
                                                className="animate-spin"
                                            />
                                        ) : (
                                            'Add'
                                        )}
                                    </BeButton>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className="flex-col items-start gap-6 rounded-xl bg-white p-8 shadow-sm">
                    <div className="flex flex-col gap-4">
                        <h3 className="text-2xl font-bold">Subscription FAQ</h3>
                        <ul className="flex flex-col gap-y-2">
                            <li className="flex items-center gap-x-1.5 leading-5">
                                <AsteriskSimple
                                    size="1.5rem"
                                    weight="bold"
                                    className="shrink-0"
                                />
                                Each user is $19.90 per month
                            </li>
                            <li className="flex items-center gap-x-1.5 leading-5">
                                <AsteriskSimple
                                    size="1.5rem"
                                    weight="bold"
                                    className="shrink-0"
                                />
                                Add and remove users any time, no lock-in
                                contracts
                            </li>
                            <li className="flex items-center gap-x-1.5 leading-5">
                                <AsteriskSimple
                                    size="1.5rem"
                                    weight="bold"
                                    className="shrink-0"
                                />
                                New users will be billed on a pro-rata basis
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddUserPage;
