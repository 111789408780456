
import {
    PLACEMENTS_EXPORT,
    PLACEMENTS_EXPORTED_ERROR,
    PLACEMENTS_LOAD,
    PLACEMENTS_LOADED,
    PLACEMENTS_LOADED_ERROR
} from "./placementActions";
import {
    exportPlacementsExportPlacementsGet, type ExportPlacementsExportPlacementsGetResponse,
    getPlacementsPlacementsGet,
    type GetPlacementsPlacementsGetResponse
} from "../../../clients/pipeline";
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import {AppDispatch} from "../../../core/state/store";
import getPipelineAxios from "../../../core/utils/getPipelineAxios";
import {PlacementScoreboard} from "../../dashboard/interfaces/placementScoreboard";

export function getPlacements(workspaceId: number, filter: string = '', sortField = '', sortOrder: 'asc'|'desc' = 'asc', userId: string = '') {
    return async (dispatch: AppDispatch) => {
        dispatch({ type: PLACEMENTS_LOAD });

        const axios = await getPipelineAxios();
        return getPlacementsPlacementsGet({
            client: axios,
            query: {
               workspace_id: workspaceId,
               count: 1000,
               filter,
               sort_by: sortField,
               order: sortOrder,
               ...(userId && { user_id: userId })
            }
        })
            .then((apiResponse: GetPlacementsPlacementsGetResponse|any) => {

                if (apiResponse.data?.status === 'success') {
                    const placements: PlacementScoreboard[] = apiResponse.data.result;

                    dispatch({
                        type: PLACEMENTS_LOADED,
                        placements: placements,
                        totalPlacements: placements.length,
                        pageLinks: {
                            first:'',
                            prev: '',
                            next: '',
                            last: ''
                        }
                    });
                }
            })
            .catch((e) => dispatch({ type: PLACEMENTS_LOADED_ERROR, error: e }));
    };
}


export function exportPlacements(workspaceId: number, filter: string = '', sortField = '', sortOrder: 'asc'|'desc' = 'asc', userId: string = '') {
    return async (dispatch: AppDispatch) => {
        dispatch({ type: PLACEMENTS_EXPORT });

        const axios = await getPipelineAxios();
        return exportPlacementsExportPlacementsGet({
            client: axios,
            responseType: 'blob',
            query: {
                workspace_id: workspaceId,
                filter,
                sort_by: sortField,
                order: sortOrder,
                ...(userId && { user_id: userId })
            }
        })
        .then((apiResponse: ExportPlacementsExportPlacementsGetResponse|any) => {
                const timestamp = DateTime.now().toFormat('yyyy-MM-dd_HH-mm-ss');
                const filename = `placements_${timestamp}.zip`;
                saveAs(apiResponse.data, filename);
        })
        .catch((e) => dispatch({ type: PLACEMENTS_EXPORTED_ERROR, error: e }));
    }
}


// export function getPlacements(pageLink = '', selectedWorkspace: WorkspaceRole) {
//     return async (dispatch: AppDispatch) => {
//         dispatch({ type: PLACEMENTS_LOAD });
//
//         const axios = await getAxios();
//         return postApiJobsGetPlacementList({
//             client: axios,
//             query: {
//                 integrationType: IntegrationType.JobAdder,
//                 workspaceId: selectedWorkspace.workspaceId,
//                 ...(pageLink && {
//                     requestMetadata: {
//                         type: MetaDataType.PaginationLink,
//                         data: pageLink
//                     }
//                 })
//             }
//         })
//             .then((r: PostApiJobsGetPlacementListResponse|any) => {
//                 dispatch({
//                     type: PLACEMENTS_LOADED,
//                     placements: r.data.results,
//                     totalPlacements: r.data.recordCount,
//                     pageLinks: {
//                         first: r.data.prevLink ? r.data.firstLink : r.data.prevLink,
//                         prev: r.data.prevLink,
//                         next: r.data.nextLink,
//                         last: r.data.nextLink? r.data.lastLink : r.data.nextLink
//                     }
//                 });
//             })
//             .catch((e) => dispatch({ type: PLACEMENTS_LOADED_ERROR, error: e }));
//     };
// }