
import {
    USERS_LOAD,
    USERS_LOADED,
    USERS_LOADED_ERROR,
    USERS_SELECT_USER, USERS_SELECTED_USER,
    USERS_SELECTED_USER_ERROR
} from "./usersActions";
import {
    getUserByIdApiUserUserIdGet,
    getUsersApiUserGet,
    type GetUsersApiUserGetResponse
} from "../../../clients/pipeline";
import {AppDispatch} from "../../../core/state/store";
import getPipelineAxios from "../../../core/utils/getPipelineAxios";


export function getUsers(workspaceId: number, pageSize = 10, pageNumber = 1, search = '') {
    return async (dispatch: AppDispatch) => {
        dispatch({ type: USERS_LOAD });

        const axios = await getPipelineAxios();
        return getUsersApiUserGet({
            client: axios,
            query: {
                workspace_id: workspaceId,
                page: pageNumber,
                page_size: pageSize,
                search
            },
        })
            .then((r:GetUsersApiUserGetResponse|any) => {
                dispatch({
                            type: USERS_LOADED,
                            users: r?.data.items,
                            totalUsers: r.data.total
                        });
            })
            .catch((e) => dispatch({ type: USERS_LOADED_ERROR, error: e }));
    };
}

export function getUser(userId: number) {
    return async (dispatch: AppDispatch) => {
        dispatch({ type: USERS_SELECT_USER });
        const axios = await getPipelineAxios();
        return getUserByIdApiUserUserIdGet({
            client: axios,
            path:{
                user_id: userId
            }
        })
            .then((r:GetUsersApiUserGetResponse|any) => {
                dispatch({
                    type: USERS_SELECTED_USER,
                    user: r.data
                });
            })
            .catch((e) => dispatch({ type: USERS_SELECTED_USER_ERROR, error: e }));
    }
}