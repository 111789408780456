import { DownloadSimple} from '@phosphor-icons/react';
import { Column } from 'primereact/column';
import {DataTable, DataTableStateEvent} from 'primereact/datatable';
import {useCallback, useEffect, useState} from 'react';
import { BeBadge, BeButton, BeInputText } from '../../core/beUi';
import PlacementsLoading from './placementsLoading';
import {AppDispatch, RootState} from "../../core/state/store";
import {useDispatch, useSelector} from "react-redux";
import {useAuth} from "../../core/hooks";
import UserPicker from "./filters/usersPicker/usersPicker";
import {FrequencyEnum} from "../dashboard/enums/frequencyEnum";
import {LatestPlacement} from "../dashboard/interfaces/latestPlacement";
import {exportPlacements, getPlacements} from "./state/placementEffects";

const InvoiceStatusTag = ({ invoiceStatus }: any) => (
    <>
    { invoiceStatus && <BeBadge
        severity={invoiceStatus === 'Paid' ? 'success' : 'danger'}
        variant="filled"
        value={invoiceStatus}
        /> }
    </>
);

const PlacementsPage = () => {

    const placements = useSelector((state: RootState) => state.placements?.placements);
    const pageLinks = useSelector((state: RootState) => state.placements?.pageLinks);
    const isLoading = useSelector((state: RootState) => state.placements?.isLoadingPlacements);
    const [selectedRows, setSelectedRows] = useState<any>(null);
    const [rows, setRows] = useState<LatestPlacement[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filters, setFilters] = useState({
        frequency: FrequencyEnum.Quarterly,
        user: 0,
        role: ''
    });
    const [sorting, setSorting] = useState<any>({
        field: 'startDate',
        order: -1,
    });
    const dispatch: AppDispatch = useDispatch();
    const { userAttributes, selectedWorkspace } = useAuth();

    const handleSortPlacements = (event: DataTableStateEvent) => {
        setSorting({
            field: event.sortField,
            order: event.sortOrder
        });
    }

    const handlePaging = useCallback((pageLink: string|null = '') => {
        if (userAttributes && selectedWorkspace?.id) {
            const user = userAttributes?.workspaceUsers.find(u => u.id === filters.user);
            const userId = user?.astuteId || '';
            dispatch(getPlacements(selectedWorkspace?.id, '', sorting.field, sorting.order === -1 ? 'desc' : 'asc', userId));
        }
    },[userAttributes, selectedWorkspace]);

    const handleSearchTermChange = useCallback((term:string) => {
        if (userAttributes && selectedWorkspace?.id) {
            setSearchTerm(term);
            const user = userAttributes?.workspaceUsers.find(u => u.id === filters.user);
            const userId = user?.astuteId || '';
            if (term === '') {
                dispatch(getPlacements(selectedWorkspace?.id, '', sorting.field, sorting.order === -1 ? 'desc' : 'asc', userId));
            }
        }
    },[selectedWorkspace]);

    const handleSearch = useCallback(() => {
        if (userAttributes && selectedWorkspace?.id) {
            const user = userAttributes?.workspaceUsers.find(u => u.id === filters.user);
            const userId = user?.astuteId || '';
            dispatch(getPlacements(selectedWorkspace?.id, searchTerm, sorting.field, sorting.order === -1 ? 'desc' : 'asc', userId));
        }
    },[searchTerm, sorting, filters, userAttributes, selectedWorkspace]);

    const handleExport = useCallback(() => {
        if (userAttributes && selectedWorkspace?.id) {
            const user = userAttributes?.workspaceUsers.find(u => u.id === filters.user);
            const userId = user?.astuteId || '';
            dispatch(exportPlacements(selectedWorkspace?.id, searchTerm, sorting.field, sorting.order === -1 ? 'desc' : 'asc', userId));
        }
    },[searchTerm, sorting, filters, userAttributes, selectedWorkspace]);

    useEffect(() => {
        if (userAttributes && selectedWorkspace?.id) {
            const user = userAttributes?.workspaceUsers.find(u => u.id === filters.user);
            const userId = user?.astuteId || '';
            dispatch(getPlacements(selectedWorkspace?.id, searchTerm, sorting.field, sorting.order === -1 ? 'desc' : 'asc', userId));
        }
    }, [filters, userAttributes, selectedWorkspace]);


    useEffect(() => {
            handlePaging();
    }, [userAttributes, sorting]);

    useEffect(() => {
        if(placements) {
            setRows(placements);
        }
    }, [placements]);

    return (
        <>
            { isLoading && <PlacementsLoading /> }
            { !isLoading && <div className="flex flex-col gap-y-6">
            <h2 className="mb-4 text-4xl font-bold text-black">Placements</h2>
            <div className="flex items-center justify-between gap-x-6">
                <div className="flex align-items-center gap-x-2">
                    <div className="p-inputgroup w-auto">
                        <BeInputText value={searchTerm} placeholder="Search..." onChange={(e) => handleSearchTermChange(e.target.value)} />
                        <BeButton
                            variant="outlined"
                            className="rounded-l-none border-l-0 bg-gray-200"
                            onClick={handleSearch}
                        >
                            Search
                        </BeButton>
                    </div>
                    <UserPicker key="user-picker" user={filters.user} onChange={(u: number) => setFilters({...filters, user: u })} />
                </div>
                <BeButton onClick={handleExport}>
                    <DownloadSimple size="1.75rem" />
                    Export
                </BeButton>
            </div>
            <div className="overflow-x-auto scrollbar-thin">
                <div className="inline-block min-w-full align-middle">

                    <DataTable
                        selectionMode={null}
                        selection={selectedRows}
                        onSelectionChange={(e) => setSelectedRows(e.value)}
                        value={rows}
                        paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]}
                        tableClassName="min-w-full max-w-max"
                        sortField={sorting.field} sortOrder={sorting.order }
                        sortMode="single"
                        lazy={true}
                        onSort={handleSortPlacements}
                    >
                        <Column
                            selectionMode="multiple"
                            headerClassName="w-8 bg-white"
                        />
                        <Column
                            field="candidateName"
                            header="Candidate Name"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            bodyClassName="text-secondary text-sm font-normal"
                            sortable
                        />
                        <Column
                            field="jobTitle"
                            header="Job Title"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            bodyClassName="text-secondary text-sm font-normal"
                            sortable
                        />
                        <Column
                            field="company"
                            header="Company"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            bodyClassName="text-secondary text-sm font-normal"
                            sortable
                        />
                        <Column
                            field="type"
                            header="Type"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                            sortable
                        />
                        <Column
                            field="recruiter"
                            header="Recruiter"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            bodyClassName="text-secondary text-sm font-normal"
                            sortable
                        />
                        <Column
                            field="totalAmount"
                            header="Total Amount"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                            sortable
                        />
                        <Column
                            field="split"
                            header="Split"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                            sortable
                        />
                        <Column
                            field="startDate"
                            header="Start Date"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                            sortable
                        />
                        <Column
                            field="invoiceId"
                            header="INV-#"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                            sortable
                        />
                        <Column
                            field="invoiceDue"
                            header="Invoice Due"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                            sortable
                        />
                        <Column
                            field="invoiceStatus"
                            header="Invoice Status"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                            sortable
                        />
                    </DataTable>

                    {/*<DataTable*/}
                    {/*    selectionMode={null}*/}
                    {/*    selection={selectedRows}*/}
                    {/*    onSelectionChange={(e) => setSelectedRows(e.value)}*/}
                    {/*    value={rows}*/}
                    {/*    tableClassName="min-w-full max-w-max"*/}
                    {/*>*/}
                    {/*    <Column*/}
                    {/*        selectionMode="multiple"*/}
                    {/*        headerClassName="w-8 bg-white"*/}
                    {/*    />*/}
                    {/*    <Column*/}
                    {/*        field="candidateName"*/}
                    {/*        header="Candidate Name"*/}
                    {/*        headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"*/}
                    {/*        bodyClassName="text-secondary text-sm font-normal"*/}
                    {/*    />*/}
                    {/*    <Column*/}
                    {/*        field="jobTitle"*/}
                    {/*        header="Job Title"*/}
                    {/*        headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"*/}
                    {/*        bodyClassName="text-secondary text-sm font-normal"*/}
                    {/*    />*/}
                    {/*    <Column*/}
                    {/*        field="company"*/}
                    {/*        header="Company"*/}
                    {/*        headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"*/}
                    {/*        bodyClassName="text-secondary text-sm font-normal"*/}
                    {/*    />*/}
                    {/*    <Column*/}
                    {/*        field="jobType"*/}
                    {/*        header="Type"*/}
                    {/*        headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"*/}
                    {/*        bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"*/}
                    {/*    />*/}
                    {/*    <Column*/}
                    {/*        field="split"*/}
                    {/*        header="Split"*/}
                    {/*        headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"*/}
                    {/*        bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"*/}
                    {/*        align="center"*/}
                    {/*    />*/}
                    {/*    <Column*/}
                    {/*        field="billing"*/}
                    {/*        header="Billing"*/}
                    {/*        headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"*/}
                    {/*        bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"*/}
                    {/*        align="center"*/}
                    {/*    />*/}
                    {/*    <Column*/}
                    {/*        field="margin"*/}
                    {/*        header="Margin"*/}
                    {/*        headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"*/}
                    {/*        bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"*/}
                    {/*        align="center"*/}
                    {/*    />*/}
                    {/*    <Column*/}
                    {/*        field="startDate"*/}
                    {/*        header="Start Date"*/}
                    {/*        headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"*/}
                    {/*        bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"*/}
                    {/*    />*/}
                    {/*    <Column*/}
                    {/*        field="invoiceNumber"*/}
                    {/*        header="INV-#"*/}
                    {/*        headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"*/}
                    {/*        bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"*/}
                    {/*    />*/}
                    {/*    <Column*/}
                    {/*        field="invoiceStatus"*/}
                    {/*        header="Invoice Status"*/}
                    {/*        headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"*/}
                    {/*        bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"*/}
                    {/*        align="center"*/}
                    {/*       // body={InvoiceStatusTag}*/}
                    {/*        body={<>&mdash;</>}*/}
                    {/*    />*/}
                    {/*</DataTable>*/}
                </div>
                {/*<div className="flex items-center justify-center space-x-3 p-4">*/}
                {/*    <BeButton variant="outlined" className="rounded-full" disabled={!pageLinks?.first} onClick={() => handlePaging(pageLinks?.first)}>*/}
                {/*        <CaretDoubleLeft size="1.2rem" />*/}
                {/*    </BeButton>*/}
                {/*     <BeButton variant="outlined" className="rounded-full" disabled={!pageLinks?.prev} onClick={() => handlePaging(pageLinks?.prev)}>*/}
                {/*        <CaretLeft size="1.2rem" />*/}
                {/*    </BeButton>*/}
                {/*    <BeButton variant="outlined" className="rounded-full" disabled={ !pageLinks?.next } onClick={() => handlePaging(pageLinks?.next)}>*/}
                {/*        <CaretRight size="1.2rem" />*/}
                {/*    </BeButton>*/}
                {/*    <BeButton variant="outlined" className="rounded-full" disabled={ !pageLinks?.last } onClick={() => handlePaging(pageLinks?.last)}>*/}
                {/*        <CaretDoubleRight size="1.2rem"  />*/}
                {/*    </BeButton>*/}
                {/*</div>*/}
            </div>
        </div> }
        </>
    );
};

//export default withConnectedApps(PlacementsPage, PlacementsLoading);
export default PlacementsPage;