import {useContext, useRef, useState} from "react";
import {Messages} from "primereact/messages";
import {useMountEffect} from "primereact/hooks";
import React from "react";
import {CircleNotch, Warning} from "@phosphor-icons/react";
import {BeButton} from "../../../../core/beUi";
import {useAuth, useToast} from "../../../../core/hooks";
import {ToastContext} from "../../../../core/contexts";
import getPipelineAxios from "../../../../core/utils/getPipelineAxios";
import {renewSubscriptionProdApiBillingRenewSubscriptionPost} from "../../../../clients/pipeline";
import {getErrorMessage} from "../../../../core/utils";

const renewSubscription = () => {

    const { userAttributes, subscriptionDetails, selectedWorkspace } = useAuth();
    const msgs = useRef<Messages>(null);
    const [loading, setLoading] = useState(false);
    const toastRef = useContext(ToastContext);
    const { showToast } = useToast(toastRef);

    const handleRenewSubscription = async () => {
        if(userAttributes && selectedWorkspace) {
            try {
                setLoading(true);
                const axios = await getPipelineAxios();
                await renewSubscriptionProdApiBillingRenewSubscriptionPost({
                    client: axios,
                    query: {
                        workspaceId: selectedWorkspace.id
                    }
                });
                showToast({
                    severity: 'success',
                    summary: 'success',
                    detail: 'Renewed successfully!',
                });

                location.reload();
            } catch (error) {
                showToast({
                    severity: 'error',
                    summary: 'failed',
                    detail: getErrorMessage(error),
                });
                setLoading(false);
            }
        }
    }

    useMountEffect(() => {
        msgs.current?.clear();
        msgs.current?.show([
            {
                severity: 'warn',
                sticky: true,
                closable: false,
                content: (
                    <div className="flex justify-center items-start">
                        <Warning size="2.5rem" className="mt-1"  />
                        <div className="ml-7">
                            { subscriptionDetails?.isSubscriptionOwner && <React.Fragment>
                                <p>If you think this is an error, please email <a href="mailto:support@workingbe.com" className="font-medium underline">support@workingbe.com</a>.</p>
                                <p>Alternatively renew your subscription below.</p>
                                <div className="mt-2 flex justify-start">
                                    <BeButton
                                        type="submit"
                                        size="lg"
                                        variant="filled"
                                        icon={loading ?  <CircleNotch
                                            size="1.75rem"
                                            className="animate-spin"
                                        /> : <></>}
                                        disabled={loading}
                                        className="mt-3"
                                        onClick={() => handleRenewSubscription()}
                                    >
                                        Renew subscription
                                    </BeButton>
                                </div>
                            </React.Fragment> }
                            { !subscriptionDetails?.isSubscriptionOwner && <React.Fragment>
                                <p>To continue using the service, please contact the account owner to update the subscription.</p>
                            </React.Fragment>}
                        </div>

                    </div>
                )
            }
        ]);
    });

    return (<div className="card flex w-full">
        <Messages ref={msgs} className="w-full"/>
    </div>);
}

export default renewSubscription;