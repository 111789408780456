import {combineReducers} from "redux";
import placementReducer from "../../pages/placements/state/placementReducer";
import appReducer from "./app/appReducer";
import dashboardReducer from "../../pages/dashboard/state/dashboardReducer";
import usersReducer from "../../pages/users/state/usersReducer";

const reducers = combineReducers({
    app: appReducer,
    users: usersReducer,
    placements: placementReducer,
    dashboard: dashboardReducer,
});

export default reducers;