import { useContext } from "react";
import { Routes } from "./routes/routes";
import { UserContext } from "./core/contexts";
import SplashLoader from "./layouts/common/splashLoader/fullscreenLoader";

function App() {

 const {
    currentUser,
    userAttributes,
    isLoading,
    hasActiveSubscription,
    subscriptionDetails,
    selectedWorkspace,
  } = useContext(UserContext);

  const isUserLoading = isLoading || userAttributes === null || hasActiveSubscription === null || selectedWorkspace === null;
  const isUserLoggedOut = !isLoading && currentUser == null;

  if (isUserLoading && !isUserLoggedOut) {
    return <SplashLoader />;
  }

  return (
      <Routes
          isLoggedOut={isUserLoggedOut}
          isAuthorized={!!currentUser}
          subscriptionDetails={subscriptionDetails}
          hasActiveSubscription={hasActiveSubscription}
          role={selectedWorkspace?.role}
          selectedWorkspace={selectedWorkspace}
      />
  );
}

export default App;
