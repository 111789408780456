import {number, object} from 'yup';
import {BeMessage} from "../../../core/beUi";


const validationSchema = object().shape({
    workspaceId: number()
        .required(() => (
            <BeMessage severity="error" text="Please select a workspace" />
        )),
});

export default validationSchema;