
import {CheckCircle, CircleNotch, DotsThree, MinusCircle, Pen, Plus, Trash} from '@phosphor-icons/react';
import { Column } from 'primereact/column';
import {DataTable, DataTableSortEvent, DataTableStateEvent} from 'primereact/datatable';
import {useContext, useEffect, useState} from 'react';
import {BeBadge, BeButton, BeInputText} from "../../core/beUi";
import {useNavigate} from "react-router-dom";
import UsersLoading from "./usersLoading";
import {UsersTable} from "./interfaces/usersTable";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../core/state/store";
import {PageOptions} from "../../core/interfaces/pageOptions";
import {ToastContext} from "../../core/contexts";
import {useAuth, useToast} from "../../core/hooks";
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog';
import getPipelineAxios from "../../core/utils/getPipelineAxios";
import {
    changeWorkspaceUserActiveStatusApiUserUserIdWorkspaceStatusPut,
    deleteUserApiUserUserIdDelete
} from "../../clients/pipeline";
import {getUsers} from "./state/usersEffects";
import {UserStatus} from "../../core/enums/userStatus";


const UserStatusTag = ({status}: any) => (
    <BeBadge
        severity={status === 'active' ? 'success' : 'danger'}
        variant="outlined"
        value={status}
    />
);

const UsersPage = () => {

    const users = useSelector((state: RootState) => state.users?.users);
    const totalUsers = useSelector((state: RootState) => state.users?.totalUsers);
    const isLoading = useSelector((state: RootState) => state.users?.isLoadingUsers);
    const [pageOptions, setPageOptions] = useState<PageOptions>({first:0, pageNumber: 1, pageSize: 10, search: ''});
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedRows, setSelectedRows] = useState<any>(null);
    const [deleting, setDeleting] = useState<number|null>(null);
    const [statusToggle, setStatusToggle] = useState<number|null>(null);
    const [rows, setRows] = useState<UsersTable[]>([]);
    const dispatch: AppDispatch = useDispatch();
    const toastRef = useContext(ToastContext);
    const { showToast } = useToast(toastRef);
    const { userAttributes, selectedWorkspace } = useAuth();

    const search = () => {
        setPageOptions({...pageOptions, search: searchTerm, first: 0, pageNumber: 1});
    }

    const onSearchTermChange = (value: string) => {
        setSearchTerm(value);
    }

    const onPageChange = (e: DataTableStateEvent) => {
        setPageOptions({...pageOptions, first: e.first, pageNumber: (e.page!+1)});
    }

    const onSort = (e: DataTableSortEvent) => {
        console.log(e);
    }

    const handleDeletion = async () => {
        if(deleting && userAttributes) {
            setDeleting(deleting);
            try {
                const axios = await getPipelineAxios();
                await deleteUserApiUserUserIdDelete({
                    client: axios,
                    path: {
                        user_id: deleting
                    }
                });
                showToast({
                    severity: 'success',
                    summary: 'success',
                    detail: 'User removed successfully!',
                });
                search();
            } catch (error) {
                console.log(error);
                if (error instanceof Error) {
                    showToast({
                        severity: 'error',
                        summary: 'failed',
                        detail:
                            error instanceof Error
                                ? error.message
                                : 'Something went wrong. Please, try again later.',
                    });
                }
            }
            setDeleting(null);
        }
    };

    const handleStatusToggle = async () => {
        if(statusToggle && selectedWorkspace) {
            setStatusToggle(statusToggle);
            try {
                const user = users?.find((u:any) => u.id === statusToggle);
                if (user && userAttributes) {
                    const axios = await getPipelineAxios();
                    await changeWorkspaceUserActiveStatusApiUserUserIdWorkspaceStatusPut({
                        client: axios,
                        path: {
                          user_id: user.id,
                        },
                        body: {
                            workspaceId: String(selectedWorkspace.id),
                            isActive: user.isActive ? UserStatus.Inactive : UserStatus.Active
                        }
                    });
                    showToast({
                        severity: 'success',
                        summary: 'success',
                        detail: 'User status successfully changed!',
                    });
                    search();
                }
            } catch (error) {
                console.log(error);
                if (error instanceof Error) {
                    showToast({
                        severity: 'error',
                        summary: 'failed',
                        detail:
                            error instanceof Error
                                ? error.message
                                : 'Something went wrong. Please, try again later.',
                    });
                }
            }
            setStatusToggle(null);
        }
    };

    const actionsBodyTemplate = (user: UsersTable) => {
       return <BeButton
           variant="ghost"
           size="sm"
           menu={[
               {
                   label: 'Edit',
                   icon: <Pen size="1.75rem" />,
                   className: 'text-red-600',
                   command: () => {
                       navigate(`/users/${user.id}`)
                   }
               },
               {
                   label: user.status === 'active'? 'Deactivate' : 'Activate',
                   icon: user.status === 'active'? <MinusCircle size="1.75rem" /> : <CheckCircle size="1.75rem" />,
                   className: 'text-red-600',
                   command: () => {
                       setStatusToggle(user.id);
                   }
               },
               {
                   label: 'Remove',
                   icon: <Trash size="1.75rem" />,
                   className: 'text-red-600',
                   command: () => {
                       confirmDialog({
                           message: <div className="-ml-4">Are you sure you want to remove this user?</div>,
                           header: 'Confirmation',
                           defaultFocus: 'none',
                           acceptLabel: 'Yes, proceed',
                           acceptClassName: 'text-white bg-danger border-none focus:ring-transparent',
                           rejectClassName: 'text-red-600 bg-white border-none focus:ring-transparent',
                           accept: () => setDeleting(user.id)
                       });

                   }
               },
           ]}
           iconOnly
       >
           { [deleting,statusToggle].includes(user.id) ?  <CircleNotch size="1.75rem" className="animate-spin"/> : <DotsThree size="1.75rem" weight="bold" /> }
       </BeButton>
    };

    useEffect(() => {
        if (!searchTerm) {
            search();
        }
    }, [searchTerm]);

    useEffect(() => {
        handleDeletion()
            .catch(error => console.log(error));
    },[deleting]);

    useEffect(() => {
        handleStatusToggle()
            .catch(error => console.log(error));
    },[statusToggle])

    useEffect(() => {
        if(users) {
            const r = users.map((u:any) => ({
                id: u.id,
                cognitoSub: u.cognitoSub,
                name: `${u.firstName} ${u.lastName}`,
                role: u.role,
                email: u.email,
                astuteId: u.astuteId,
                status: u.isActive ? 'active' : 'inactive'
            }));
            setRows(r);
        }
    }, [users]);

    useEffect(() => {
        if(selectedWorkspace?.id) {
            dispatch(getUsers(selectedWorkspace?.id, pageOptions.pageSize, pageOptions.pageNumber, pageOptions.search));
        }
    }, [pageOptions, selectedWorkspace]);

    const navigate = useNavigate();
    return (
        <>
            { isLoading && <UsersLoading /> }
            { !isLoading &&  <>
                <ConfirmDialog />
                <div className="flex flex-col gap-y-6">
                <h1 className="mb-4 text-4xl font-bold text-black">Users</h1>
                <div className="flex flex-col gap-y-6 sm:flex-row sm:items-center sm:justify-between sm:gap-x-6">
                    <div className="p-inputgroup w-auto">
                        <BeInputText placeholder="Search..." value={searchTerm} onChange={(e)=> onSearchTermChange(e.target.value)} />
                        <BeButton
                            variant="outlined"
                            className="rounded-l-none border-l-0 bg-gray-200"
                            onClick={() => search()}
                        >
                            Search
                        </BeButton>
                    </div>
                    <BeButton onClick={() => navigate('/users/add')}>
                        <Plus size="1.75rem" />
                        Add User
                    </BeButton>
                </div>
                <div className="overflow-x-auto scrollbar-thin">
                    <div className="inline-block min-w-full align-middle">
                        <DataTable
                            selectionMode="checkbox"
                            selection={selectedRows}
                            onSelectionChange={(e) => setSelectedRows(e.value)}
                            value={rows}
                            paginator
                            lazy
                            first={pageOptions.first}
                            onPage={onPageChange}
                            onSort={onSort}
                            rows={pageOptions.pageSize}
                            totalRecords={totalUsers}
                            tableClassName="min-w-full max-w-max"
                            rowClassName={() => 'cursor-pointer hover:bg-gray-50'}
                            onRowClick={(e) => navigate(`/users/${e.data.id}`)}
                        >
                            <Column
                                selectionMode="multiple"
                                headerClassName="w-8 bg-white"
                            />
                            <Column
                                field="name"
                                header="Name"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"

                            />
                            <Column
                                field="astuteId"
                                header="Astute ID"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"

                            />
                            <Column
                                field="email"
                                header="Email"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"

                            />
                            <Column
                                field="role"
                                header="Role"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"

                            />
                            <Column
                                field="status"
                                header="Status"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                                align="center"
                                body={UserStatusTag}

                            />
                            <Column
                                header="Action"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                                body={actionsBodyTemplate}
                            />
                        </DataTable>
                    </div>
                </div>
            </div> </>
            }
        </>
    );
};

//export default withConnectedApps(UsersPage, UsersLoading);
export default UsersPage;