import {AsteriskSimple, CircleNotch, Play, Prohibit} from '@phosphor-icons/react';
import {AddressElement, PaymentElement, useElements, useStripe,} from '@stripe/react-stripe-js';
import {FormEvent, useContext, useState} from 'react';
import { getErrorMessage} from '../../core/utils';
import { BeButton} from '../../core/beUi';
import {useToast} from '../../core/hooks';
import {ToastContext, UserContext} from '../../core/contexts';
import BillingLoading from "./billingLoading";
import {ConfirmDialog} from 'primereact/confirmdialog';
import SubscriptionDetails from "./components/subscriptionDetails/subscriptionDetails";



const BillingForm = () => {
    const { userAttributes } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [isStripeLoading, setIsStripeLoading] = useState(true);
    const stripe = useStripe();
    const elements = useElements();
    const toastRef = useContext(ToastContext);
    const { showToast } = useToast(toastRef);

    const handlePayment = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!stripe || !elements) return;
        try {
            setLoading(true);
            const { error } = await stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url: `${process.env.REACT_APP_BASE_URL}/billing/verify`,
                },
            });

            if (error) {
                showToast({
                    severity: 'error',
                    summary: 'failed',
                    detail: error.message,
                });
            }
        } catch (error) {
            showToast({
                severity: 'error',
                summary: 'failed',
                detail: getErrorMessage(error),
            });
        }
        setLoading(false);
    };


    return (
        <>
            <ConfirmDialog />
            {isStripeLoading && <BillingLoading />}
            <div className={`${isStripeLoading ? 'hidden' : 'flex'} flex-col gap-y-6`}>

                    <div className="flex flex-col justify-between align-middle lg:flex-row">
                        <h2 className="mb-4 text-4xl font-bold text-black">
                            Billing Details
                        </h2>
                    </div>


                    <div className="flex flex-col-reverse gap-7 lg:flex-row lg:items-start">
                        <div className="flex grow flex-col gap-y-6">
                            <SubscriptionDetails />
                            <form onSubmit={handlePayment}>
                                <PaymentElement
                                    onReady={() => setIsStripeLoading(false)}
                                    options={{
                                        layout: 'accordion',
                                        defaultValues: {
                                            billingDetails: {
                                                name: `${userAttributes?.given_name ?? ''} ${userAttributes?.family_name ?? ''}`,
                                                email: userAttributes?.email,
                                                phone: userAttributes?.phone_number,
                                            },
                                        },
                                    }}
                                    className="mb-6"
                                />
                                <h3 className="my-4 text-3xl font-bold text-black">
                                    Billing Address
                                </h3>
                                <AddressElement
                                    // onChange={(e) => console.log(e)}
                                    options={{
                                        mode: 'billing',
                                        display: {
                                            name: 'split',
                                        },
                                        fields: {
                                            phone: 'always',
                                        },
                                        validation: {
                                            phone: {
                                                required: 'always',
                                            },
                                        },
                                        defaultValues: {
                                            firstName: userAttributes?.given_name,
                                            lastName: userAttributes?.family_name,
                                            address: {
                                                country:
                                                    userAttributes?.[
                                                        'custom:country'
                                                        ] || 'AU',
                                            },
                                            phone: userAttributes?.phone_number,
                                        },
                                    }}
                                />
                                <div className="flex justify-between gap-x-4 align-middle">
                                    <BeButton
                                        type="submit"
                                        size="lg"
                                        fullWidth
                                        disabled={loading}
                                        className="mt-3"
                                    >
                                        {loading ? (
                                            <CircleNotch
                                                size="1.75rem"
                                                className="animate-spin"
                                            />
                                        ) : (
                                            'Update payment details'
                                        )}
                                    </BeButton>
                                </div>
                            </form>
                        </div>
                        <div
                            className="flex-col items-start gap-6 rounded-md border border-[#e6e6e6] bg-white p-6 shadow-sm">
                            <div className="flex flex-col gap-4">
                                <h3 className="text-2xl font-bold">
                                    Subscription FAQ
                                </h3>
                                <ul className="flex flex-col gap-y-2">
                                    <li className="flex items-center gap-x-1.5 leading-5">
                                        <AsteriskSimple
                                            size="1.5rem"
                                            weight="bold"
                                            className="shrink-0"
                                        />
                                        Each user is $19.90 per month
                                    </li>
                                    <li className="flex items-center gap-x-1.5 leading-5">
                                        <AsteriskSimple
                                            size="1.5rem"
                                            weight="bold"
                                            className="shrink-0"
                                        />
                                        Add and remove users any time, no lock-in
                                        contracts
                                    </li>
                                    <li className="flex items-center gap-x-1.5 leading-5">
                                    <AsteriskSimple
                                            size="1.5rem"
                                            weight="bold"
                                            className="shrink-0"
                                        />
                                        New users will be billed on a pro-rata basis
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

            </div>
            </>
            );
            };

            export default BillingForm;
