import React from "react";
import {useAuth} from "../../../core/hooks";


const ContactDetails = () =>{

    const { userAttributes } = useAuth();

    return (
        <div className="rounded-md border border-[#e6e6e6] bg-white p-6 shadow-sm">
            <div className="mb-4 flex items-center justify-between">
                <h2 className="text-xl font-bold text-black">Contacts</h2>
            </div>
            <div className="flex w-full flex-col gap-y-2">
                <div className="flex w-full flex-row">
                    <span className="flex-1 sm:min-w-48">Email</span>
                    <div className="font-semibold sm:flex-1">{userAttributes?.email}</div>
                </div>
                <div className="flex w-full flex-row">
                    <span className="flex-1 sm:min-w-48">Phone</span>
                    <div className="font-semibold sm:flex-1 ">{userAttributes?.phone_number}</div>
                </div>
            </div>
        </div>
    );
}

export default ContactDetails;