import {SubscriptionStatus} from "../../../../core/contexts/User/types";
import {DateTime} from "luxon";
import {getSubscriptionStatusBadge} from "../../utils";
import React, {useContext, useState} from "react";
import {useAuth, useToast} from "../../../../core/hooks";
import {BeButton} from "../../../../core/beUi";
import {CircleNotch, CreditCard, Play, Prohibit} from "@phosphor-icons/react";
import getPipelineAxios from "../../../../core/utils/getPipelineAxios";
import {
    cancelScheduledCancellationProdApiBillingCancelScheduledCancellationPost,
    cancelSubscriptionProdApiBillingCancelSubscriptionPost
} from "../../../../clients/pipeline";
import {getErrorMessage} from "../../../../core/utils";
import {confirmDialog} from "primereact/confirmdialog";
import {ToastContext} from "../../../../core/contexts";
import {useNavigate} from "react-router-dom";
import RenewSubscription from "../renewSubscription/renewSubscription";
import {UserRole} from "../../../../core/enums/userRole";

const subscriptionDetails = () => {

    const [cancelling, setCancelling] = useState(false);
    const { userAttributes, selectedWorkspace, subscriptionDetails } = useAuth();
    const toastRef = useContext(ToastContext);
    const { showToast } = useToast(toastRef);
    const navigate = useNavigate();

    const handleCancellation = async () => {
        if(userAttributes && selectedWorkspace) {
            try {
                setCancelling(true);
                const axios = await getPipelineAxios();
                await cancelSubscriptionProdApiBillingCancelSubscriptionPost({
                    client: axios,
                    query: {
                        workspaceId: selectedWorkspace.id
                    }
                });
                showToast({
                    severity: 'success',
                    summary: 'success',
                    detail: 'Cancelled successfully!',
                });
                // location.reload();
            } catch (error) {
                showToast({
                    severity: 'error',
                    summary: 'failed',
                    detail: getErrorMessage(error),
                });
                setCancelling(false);
            }
            location.reload();
        }
    }

    const handleScheduledCancellation = async () => {
        if(userAttributes && selectedWorkspace) {
            try {
                setCancelling(true);
                const axios = await getPipelineAxios();
                await cancelScheduledCancellationProdApiBillingCancelScheduledCancellationPost({
                    client: axios,
                    query: {
                        workspaceId: selectedWorkspace.id
                    }
                });
                showToast({
                    severity: 'success',
                    summary: 'success',
                    detail: 'Cancelled scheduled cancellation successfully!',
                });
                // location.reload();
            } catch (error) {
                showToast({
                    severity: 'error',
                    summary: 'failed',
                    detail: getErrorMessage(error),
                });
                setCancelling(false);
            }
            location.reload();
        }
    }

    const handleCancelSubscription = async (e:any) => {
        e.preventDefault();
        const endOfSubscription = subscriptionDetails ? `on the ${DateTime.fromISO(subscriptionDetails.nextPaymentDueDate).toLocaleString(DateTime.DATE_MED)}` : 'Now';
        confirmDialog({
            message: <div className="flex flex-col">
                <div className="-ml-4">If you proceed, your subscription will end {endOfSubscription}.</div>
                <div className="-ml-4">Are you sure you want to cancel your subscription?</div>
            </div>,
            header: 'Confirmation',
            defaultFocus: 'none',
            acceptLabel: 'Yes, proceed',
            acceptClassName: 'text-white bg-danger border-none focus:ring-transparent',
            rejectClassName: 'text-red-600 bg-white border-none focus:ring-transparent',
            accept: async () => handleCancellation()
        });
    }

    const handleCancelScheduledCancellation = async (e:any) => {
        e.preventDefault();
        const endOfSubscription = subscriptionDetails ? `on the ${DateTime.fromISO(subscriptionDetails.nextPaymentDueDate).toLocaleString(DateTime.DATE_MED)}` : 'Now';
        confirmDialog({
            message: <div className="flex flex-col">
                <div className="-ml-4">If you proceed, you will be billed {endOfSubscription}.</div>
                <div className="-ml-4">Are you sure you want to cancel the scheduled cancellation?</div>
            </div>,
            header: 'Confirmation',
            defaultFocus: 'none',
            acceptLabel: 'Yes, proceed',
            acceptClassName: 'text-white bg-danger border-none focus:ring-transparent',
            rejectClassName: 'text-red-600 bg-white border-none focus:ring-transparent',
            accept: async () => handleScheduledCancellation()
        });
    }

    return (<div className="rounded-md border border-[#e6e6e6] bg-white p-6 shadow-sm">
        <div className="mb-4 flex justify-between items-center">
            <h2 className="text-xl font-bold text-black">Subscription</h2>
            { selectedWorkspace?.role === UserRole.OWNER && subscriptionDetails?.status &&
                [SubscriptionStatus.ACTIVE,SubscriptionStatus.TRIALING].includes(subscriptionDetails?.status) &&
                subscriptionDetails?.hasPaymentMethod &&
                <div>
                {!subscriptionDetails?.cancelAtPeriodEnd && <div><BeButton
                    size="sm"
                    icon={cancelling ? <CircleNotch size="1.75rem" className="animate-spin"/> : <Prohibit size="1.5rem"/>}
                    variant="danger"
                    disabled={cancelling}
                    onClick={handleCancelSubscription}
                >Cancel subscription</BeButton></div>}

                {subscriptionDetails?.cancelAtPeriodEnd && <div><BeButton
                    size="sm"
                    icon={cancelling ? <CircleNotch size="1.75rem" className="animate-spin"/> : <Play size="1.5rem"/>}
                    variant="danger"
                    disabled={cancelling}
                    onClick={handleCancelScheduledCancellation}
                >Resume subscription</BeButton></div>}
            </div> }
            { subscriptionDetails?.status === SubscriptionStatus.CANCELED && <div>
                {!subscriptionDetails.hasPaymentMethod && <div><BeButton
                    size="sm"
                    icon={<CreditCard size="1.5rem"/>}
                    variant="warning"
                    onClick={() => navigate('/billing')}
                >Add payment details</BeButton></div>}
            </div>}
        </div>
        {subscriptionDetails && <div className="flex flex-col gap-y-2 w-full">
            <div className="flex w-full flex-row">
                <span className="min-w-48 flex-1">Users</span>
                <div className="flex-1 font-semibold">{subscriptionDetails.seatQty}</div>
            </div>
            <div className="flex w-full flex-row">
                <span className="min-w-48 flex-1">WorkingBe</span>
                <div className="flex-1 font-semibold">${(subscriptionDetails.pricePerSeat / 100).toFixed(2)}
                    <span className="ml-2 font-light">AUD a user per month</span></div>
            </div>
            <div className="flex w-full flex-row">
                <span className="min-w-48 flex-1">Next Payment Date</span>
                <div
                    className="flex-1 font-semibold">{[SubscriptionStatus.PAST_DUE, SubscriptionStatus.UNPAID,].includes(subscriptionDetails.status) ? "Now" : `${DateTime.fromISO(subscriptionDetails.nextPaymentDueDate).toLocaleString(DateTime.DATE_MED)}`}</div>
            </div>
            <div className="flex flex-row">
                <span className="min-w-48 flex-1">Total Subscription Amount</span>
                <div
                    className="flex-1 font-semibold">${(subscriptionDetails.totalSubscriptionAmount / 100).toFixed(2)}
                    <span className="ml-2 font-light">AUD including GST</span></div>
            </div>
            <div className="flex flex-row">
                <span className="min-w-48 flex-1">Subscription Status</span>
                <div
                    className="flex flex-1 align-middle font-semibold">{getSubscriptionStatusBadge(subscriptionDetails.status, subscriptionDetails.trialEnd, subscriptionDetails.cancelAtPeriodEnd, subscriptionDetails.hasPaymentMethod)}</div>
            </div>

            { selectedWorkspace?.role === UserRole.OWNER && subscriptionDetails?.status === SubscriptionStatus.CANCELED && subscriptionDetails.hasPaymentMethod && <RenewSubscription /> }

        </div>}
    </div>);
}

export default subscriptionDetails;