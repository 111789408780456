import {
    AuthUser,
    FetchUserAttributesOutput,
} from '@aws-amplify/auth';
import {IntegrationType} from "../../enums/integrationType";
import {UserRole} from "../../enums/userRole";

export type IntegrationProps = {
    id: number;
    integrationId: number;
    propKey: string;
    propValue: string;
}

export type Integration = {
    id: number;
    type: IntegrationType;
    name: string;
    workspaceId: number;
    createdDateTime: string;
    props: IntegrationProps[]
};

export type Workspace = {
    id: number;
    name : string;
    emailDomain: string;
    userId: number;
    userLimitCount: number;
    role: UserRole;
    integrations: any[],
    created_at: string;

}

export type WorkspaceRole = {
    id?: number;
    role: UserRole;
}

export type WorkspaceUser = {
    country: string;
    email: string;
    firstName: string;
    id: number;
    lastName: string;
    phoneNumber: string;
    role: UserRole;
    userId: number;
    workspaceId: number;
    astuteId: string;
}

export type UserAttributes = FetchUserAttributesOutput & {
    id: number;
    astuteId: string;
    workspaces: Workspace[];
    workspaceUserRoles: WorkspaceRole[];
    workspaceUsers: WorkspaceUser[];
};

export type UserContextValue = {
    currentUser: AuthUser | null;
    userAttributes: UserAttributes | null;
    isLoading: boolean;
    refreshUserAttributes?: () => Promise<void>;
    subscriptionDetails: SubscriptionDetails | null;
    hasActiveSubscription: boolean | null;
    workspaces: Workspace[];
    selectedWorkspace?: Workspace | null;
    switchWorkspace?: (workspaceId: number) => Promise<void>;
};

export type SubscriptionDetails = {
    subscriptionId: string;
    subscriptionItemId: string;
    customerId: string;
    status: SubscriptionStatus;
    trialStart: string;
    trialEnd: string;
    trialDaysRemaining: number;
    pricePerSeat: number;
    seatQty: number;
    totalSubscriptionAmount: number;
    nextPaymentDueDate: string;
    cancelAtPeriodEnd: boolean;
    hasPaymentMethod: boolean;
    isSubscriptionOwner: boolean;
}

// subscription statuses - https://mrcoles.com/stripe-api-subscription-status/
export enum SubscriptionStatus {
    INCOMPLETE ="incomplete",
    INCOMPLETE_EXPIRED = "incomplete_expired",
    TRIALING = "trialing",
    ACTIVE = "active",
    PAST_DUE = "past_due",
    CANCELED = "canceled",
    UNPAID = "unpaid",
}
